import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import Icon from "../custom-widgets/icon";
import styles from "./custom-large-cards.module.scss";

/**
 * @typedef {Object} Cards
 * @property {string} id - Required string id of the card.
 * @property {number} cardNumber - Required number of the card.
 * @property {GatsbyImageData} imgData - Optional Gatsby image data.
 * @property {string} altText - Optional string image alt text.
 * @property {string} heading - Required green title text of the card.
 * @property {string} text - Optional h4 text below the title of the card.
 * @property {string} btnText - Optional button text to go to the link.
 * @property {string} linkUrl - Optional link string where to go like "/personal-banking".
 * @property {React.JSX.Element} customContent - Optional react custom component. If passed, it renders instead of the text.
 */

/**
 * @typedef {Object} CardData
 * @property {number} slotNumber - Required number of the card.
 * @property {Cards[]} cards - Required array of objects with the data and content for the card.
 */

/**
 * This is the component to render the large cards in a row, it can handle just 2 cards for now.
 *
 * NOTE: please do NOT change the margin-bottom classes on these cards, it should be "mb-3 mb-lg-4".
 * Do NOT override the spacing using padding or margin classes on the containerClass.
 * If you need to override the section padding because the cards are connected
 * to other sections that need to look like one section, pass the sectionClass
 * "section-padding-top", "section-padding-none", "section-padding-bottom".
 * 
 * @param {Object} props - The object containing props.
 * @param {string} props.containerClass - div container classes like "bg-info".
 * @param {number[]} props.defaultCardsArray - defaultCardsArray picks the first card from each slot i.e., [1,1].
 * @param {CardData[]} props.cardData - optional Array of objects.
 *   - `slotNumber`: required number of the card.
 *   - `cards`: required array of objects with the data and content for the card.
 * @param {"en" | "es"} props.lang - "en" or "es".
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const LargePersonalizationCards = ({
  containerClass = "bg-info",
  defaultCardsArray = [1, 1],
  cardData: propCardData = null,
  lang = "en"
}) => {
  const imgData = useStaticQuery(graphql`
    {
      card1ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-greenlight-112624.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2AImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-business-banking-092524.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2NevadaImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-nevada-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2IdahoImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-idaho-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2ArizonaImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-arizona-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2OregonImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-oregon-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2WashingtonImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-washington-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2TexasImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-texas-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2UtahImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-utah-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2NewMexicoImageVar: file(
        relativePath: { eq: "cards/personalization/thumbnail-new-mexico-small-businesses-101724.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  /*
    NOTE: headings (Title) should be limited to 50 characters, and text (description) limited to 95 characters.
    as per design in Figma requirements.
  */
  const defaultCardData = [
    {
      // CARD SLOT 1
      slotNumber: 1,
      cards: [
        {
          cardNumber: 1,
          id: "home-large-personalization-card-1-default",
          imgData: imgData.card1ImageVar.childImageSharp.gatsbyImageData,
          altText: "Smiling boy holding and looking at his mobile phone.",
          heading: "Give the Gift of Financial Freedom With Greenlight",
          subheading: null,
          text: "Kids get a debit card with parental controls and much more, courtesy of WaFd Bank.",
          linkUrl: "/personal-banking/greenlight-kids-debit-card",
          btnText: "Get Greenlight",
          stretchedLink: true
        }
      ]
    },
    {
      // CARD SLOT 2
      slotNumber: 2,
      cards: [
        // Default - Business Banking
        {
          cardNumber: 1,
          id: "home-large-personalization-card-2-default",
          imgData: imgData.card2AImageVar.childImageSharp.gatsbyImageData,
          altText: "Business owner and employee looking at a tablet.",
          heading: "Find a Business Banking Partner in Wafd Bank",
          text: "With personalized tools and support, WaFd is here to help your business succeed.",
          btnText: "Find Tools & Services",
          linkUrl: "/business-banking/small-business",
          stretchedLink: true
        },
        // Nevada - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-nevada",
          imgData: imgData.card2NevadaImageVar.childImageSharp.gatsbyImageData,
          altText: "Las Vegas skyline and residential area.",
          heading: "WaFd Bank's business clients thrive in our Nevada communities!",
          text: null,
          linkUrl: "/locations/nevada",
          btnText: "WaFd in Nevada",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "Tradewind Properties",
              businessLink: "https://www.almaapartments.com",
              businessLinkId: "nevada-card-sb-link-1",
              logoSrc: "/images/personalization/nevada-tradewind-alma-apartments-logo.svg"
            },
            {
              businessName: "Siegel Suites",
              businessLink: "https://www.siegelsuites.com",
              businessLinkId: "nevada-card-sb-link-2",
              logoSrc: "/images/personalization/nevada-siegel-suites-logo.svg"
            },
            {
              businessName: "Zippy's",
              businessLink: "https://www.zippys.com/lasvegas",
              businessLinkId: "nevada-card-sb-link-3",
              logoSrc: "/images/personalization/nevada-zippys-logo.svg"
            }
          ]
        },
        // Idaho - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-idaho",
          imgData: imgData.card2IdahoImageVar.childImageSharp.gatsbyImageData,
          altText: "Downtown skyline in Boise, Idaho with hot air balloons.",
          heading: "WaFd Bank's business clients thrive in our Idaho communities!",
          text: null,
          linkUrl: "/locations/idaho",
          btnText: "WaFd in Idaho",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "Revolutionary Shower",
              businessLink: "https://revolutionaryshowersystems.com/",
              businessLinkId: "idaho-card-sb-link-1",
              logoSrc: "/images/personalization/idaho-rss-logo.svg"
            },
            {
              businessName: "Keller Williams",
              businessLink: "https://kweastidaho.com/",
              businessLinkId: "idaho-card-sb-link-2",
              logoSrc: "/images/personalization/idaho-keller-williams-logo.svg"
            },
            {
              businessName: "Payette Steel",
              businessLink: "https://payettesteel.com/",
              businessLinkId: "idaho-card-sb-link-3",
              logoSrc: "/images/personalization/idaho-payette-steel-logo.svg"
            }
          ]
        },
        // Arizona - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-arizona",
          imgData: imgData.card2ArizonaImageVar.childImageSharp.gatsbyImageData,
          altText: "Downtown skyline in Tucson, Arizona.",
          heading: "WaFd Bank's business clients thrive in our Arizona communities!",
          text: null,
          linkUrl: "/locations/arizona",
          btnText: "WaFd in Arizona",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "All City Towing",
              businessLink: "https://www.allcitytowing.com/",
              businessLinkId: "arizona-card-sb-link-3",
              logoSrc: "/images/personalization/arizona-all-city-towing-logo.svg"
            },
            {
              businessName: "iWired",
              businessLink: "https://www.iwired.com/contact-us/",
              businessLinkId: "arizona-card-sb-link-4",
              logoSrc: "/images/personalization/arizona-iwired-logo.svg"
            },
            {
              businessName: "Roadrunner Glass",
              businessLink: "https://www.roadrunnerglassco.com/",
              businessLinkId: "arizona-card-sb-link-2",
              logoSrc: "/images/personalization/arizona-roadrunner-glass-logo.svg"
            }
          ]
        },
        // Oregon - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-oregon",
          imgData: imgData.card2OregonImageVar.childImageSharp.gatsbyImageData,
          altText: "Downtown skyline in Portland, Oregon.",
          heading: "WaFd Bank's business clients thrive in our Oregon communities!",
          text: null,
          linkUrl: "/locations/oregon",
          btnText: "WaFd in Oregon",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "United Way MWV",
              businessLink: "https://www.unitedwaymwv.org",
              businessLinkId: "oregon-card-sb-link-1",
              logoSrc: "/images/personalization/oregon-united-way-logo.svg"
            },
            {
              businessName: "Pavati",
              businessLink: "https://pavati.com/",
              businessLinkId: "oregon-card-sb-link-2",
              logoSrc: "/images/personalization/oregon-pavati-logo.svg"
            },
            {
              businessName: "HPI",
              businessLink: "https://highwayproducts.com/",
              businessLinkId: "oregon-card-sb-link-3",
              logoSrc: "/images/personalization/oregon-hpi-logo.svg"
            }
          ]
        },
        // Washington - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-washington",
          imgData: imgData.card2WashingtonImageVar.childImageSharp.gatsbyImageData,
          altText: "Downtown Seattle skyline with Space Needle.",
          heading: "WaFd Bank's business clients thrive in our Washington communities!",
          text: null,
          linkUrl: "/locations/washington",
          btnText: "WaFd in Washington",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "Bishop Spray Service",
              businessLink: "http://bishopsprayservice.com/bishop-spray-service/",
              businessLinkId: "washington-card-sb-link-1",
              logoSrc: "/images/personalization/bishop-spray-service-logo.svg"
            },
            {
              businessName: "Canlis",
              businessLink: "https://canlis.com/",
              businessLinkId: "washington-card-sb-link-2",
              logoSrc: "/images/personalization/canelis-logo.svg"
            },
            {
              businessName: "KD Water Systems",
              businessLink: "https://www.kdwatersystems.com/",
              businessLinkId: "washington-card-sb-link-3",
              logoSrc: "/images/personalization/kd-water-systems-logo.svg"
            }
          ]
        },
        // Texas - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-texas",
          imgData: imgData.card2TexasImageVar.childImageSharp.gatsbyImageData,
          altText: "Downtown skyline of El Paso, Texas.",
          heading: "WaFd Bank's business clients thrive in our Texas communities!",
          text: null,
          linkUrl: "/locations/texas",
          btnText: "WaFd in Texas",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "Lynx",
              businessLink: "https://www.lynxproduction.com/",
              businessLinkId: "texas-card-sb-link-1",
              logoSrc: "/images/personalization/lynx-production-logo.svg"
            },
            {
              businessName: "USEDC",
              businessLink: "https://www.usedc.com/",
              businessLinkId: "texas-card-sb-link-2",
              logoSrc: "/images/personalization/usedc-logo.svg"
            }
          ]
        },
        // Utah - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-utah",
          imgData: imgData.card2UtahImageVar.childImageSharp.gatsbyImageData,
          altText: "Downtown skyline in Salt Lake City, Utah.",
          heading: "WaFd Bank's business clients thrive in our Utah communities!",
          text: null,
          linkUrl: "/locations/utah",
          btnText: "WaFd in Utah",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "Kyoto",
              businessLink: "https://www.kyotoslc.com/",
              businessLinkId: "utah-card-sb-link-1",
              logoSrc: "/images/personalization/utah-kyoto-logo.svg"
            },
            {
              businessName: "Golden Braid",
              businessLink: "https://www.goldenbraidslc.com/",
              businessLinkId: "utah-card-sb-link-2",
              logoSrc: "/images/personalization/utah-golden-braid-logo.svg"
            },
            {
              businessName: "Milestone",
              businessLink: "https://mfe.dev/about-us/",
              businessLinkId: "utah-card-sb-link-3",
              logoSrc: "/images/personalization/utah-milestone-logo.svg"
            }
          ]
        },
        // New Mexico - Business Clients
        {
          cardNumber: 2,
          id: "home-large-personalization-card-2-business-clients-new-mexico",
          imgData: imgData.card2NewMexicoImageVar.childImageSharp.gatsbyImageData,
          altText: "Downtown skyline of Albuquerque, New Mexico.",
          heading: "WaFd Bank's business clients thrive in our New Mexico communities!",
          text: null,
          linkUrl: "/locations/new-mexico",
          btnText: "WaFd in New Mexico",
          stretchedLink: false,
          stateSmallBusinesses: [
            {
              businessName: "LDM",
              businessLink: "https://legacydm.net/",
              businessLinkId: "new-mexico-card-sb-link-1",
              logoSrc: "/images/personalization/new-mexico-ldm-logo.svg"
            },
            {
              businessName: "Heritage",
              businessLink: " https://www.hhandr.com/",
              businessLinkId: "new-mexico-card-sb-link-2",
              logoSrc: "/images/personalization/new-mexico-heritage-logo.svg"
            },
            {
              businessName: "JL Gray",
              businessLink: "https://jlgray.com/",
              businessLinkId: "new-mexico-card-sb-link-3",
              logoSrc: "/images/personalization/new-mexico-jl-gray-logo.svg"
            }
          ]
        }
      ]
    }
  ];
  // Use Props cardData if found, else use the default (used here due to imageVariable being used on the cards).
  const cardData = propCardData || defaultCardData;
  const btnText = lang === "es" ? "Conoce más" : "Learn More";

  // NOTE: the slotCards and showHideClass logic below is for local build testing only, we can remove this show/hide logic once Tealium is handling that.
  const slotCards = defaultCardsArray; // show the defaults for now
  // defaultCardsArray picks the first card from each slot i.e., [1,1]

  return (
    <div id="homepage-large-personalization-cards" className={`border-0 ${containerClass}`}>
      <div className="container">
        <ul className="row mb-0 pl-0 list-unstyled row-cols-1 row-cols-md-2">
          {cardData.map((slot, slotIndex) => {
            return (
              <li className="col-md mb-3 mb-lg-4 " key={slot.slotNumber}>
                <div id={`home-large-personalization-card-slot-${slot.slotNumber}`} className="h-100">
                  {slot.cards.map((card, cardIndex) => {
                    let showHideClass =
                      slotIndex + 1 === slot.slotNumber && card.cardNumber === slotCards[slotIndex]
                        ? "d-flex"
                        : "d-none";
                    return (
                      <div
                        id={card.id}
                        key={cardIndex}
                        tabIndex={0}
                        className={`card h-100 overflow-hidden border-radius-12 position-relative border-0 ${showHideClass} ${styles.largeCardContainer}`}
                      >
                        {card.imgData && (
                          <GatsbyImage
                            className={`border-radius-12 border-radius-bottom-0`}
                            image={card.imgData}
                            alt={card.altText}
                          />
                        )}
                        <div className="card-body pb-0">
                          {card.heading && (
                            <h2 className="text-success font-weight-semi-bold" style={{ textDecoration: "none" }}>
                              {card.heading}
                            </h2>
                          )}
                          {card.text && <h4 dangerouslySetInnerHTML={{ __html: card.text }} />}
                          {card.stateSmallBusinesses &&
                            card.stateSmallBusinesses.map((sb, index) => (
                              <div className="row mb-4 align-items-center" key={index}>
                                <div className="col-auto">
                                  <img src={sb.logoSrc} alt={`${sb.businessName} Logo`} style={{ width: "120px" }} />
                                </div>
                                <div className="col">
                                  <h4 className="mb-0">
                                    <a
                                      className="text-decoration-none d-flex align-items-baseline"
                                      href={sb.businessLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      id={sb.businessLinkId}
                                    >
                                      <Icon name="external-link-alt" lib="far" class="mr-2" />
                                      {sb.businessName}
                                    </a>
                                  </h4>
                                </div>
                              </div>
                            ))}
                        </div>
                        {card.linkUrl && (
                          <div className="card-footer bg-white border-0 border-radius-bottom-12">
                            <div className="row">
                              <div className="col-sm-8 col-md-12 col-lg-6 position-static">
                                <Link
                                  id={`homepage-large-card-${card.cardNumber}-btn`}
                                  className={`btn btn-primary w-100 ${card.stretchedLink ? "stretched-link" : ""}`}
                                  to={card.linkUrl}
                                >
                                  {card.btnText || btnText}
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default LargePersonalizationCards;

LargePersonalizationCards.propTypes = {
  containerClass: PropTypes.string,
  defaultCardsArray: PropTypes.arrayOf(PropTypes.number),
  cardData: PropTypes.arrayOf(
    PropTypes.shape({
      slotNumber: PropTypes.number.isRequired,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          cardNumber: PropTypes.number.isRequired,
          imgData: PropTypes.object,
          altText: PropTypes.string,
          heading: PropTypes.string.isRequired,
          text: PropTypes.string,
          btnText: PropTypes.string,
          linkUrl: PropTypes.string,
          customContent: PropTypes.node
        })
      ).isRequired
    })
  ),
  lang: PropTypes.oneOf(["en", "es"])
};
